<template>
  <div class="bgcard">
    <div class="searchlist">
      <div class="select_b" style="margin-right: 0.2rem">
        <span style="color: #bbbbbb">{{ $t("lang.Isitonline") }}：</span>
        <el-select
          style="height: 0.4rem; width: 1.5rem"
          v-model="value"
          :placeholder="$t('lang.Please')"
          popper-class="select_a"
          @change="stautasond"
        >
          <el-option :label="$t('lang.whole')" value=""></el-option>
          <el-option :label="$t('lang.onLine')" :value="1"></el-option>
          <el-option :label="$t('lang.outLine')" :value="2"></el-option>
        </el-select>
      </div>
      <div class="select_b" style="margin-right: 0.2rem">
        <span style="color: #bbbbbb">{{ $t("lang.state") }}：</span>
        <el-select
          style="height: 0.4rem; width: 1.5rem"
          v-model="value"
          :placeholder="$t('lang.Please')"
          popper-class="select_a"
          @change="stauts"
        >
          <el-option :label="$t('lang.whole')" value=""></el-option>
          <el-option :label="$t('lang.normal')" :value="1"></el-option>
          <el-option :label="$t('lang.nonormal')" :value="2"></el-option>
        </el-select>
      </div>
      <div class="query">
        <div class="inputname">
          <input
            v-model="input"
            :placeholder="$t('lang.Pleaseenterausernameoremailaddress')"
          />
        </div>
        <div class="searchbtn">
          <button @click="sear">
            <p>{{ $t("lang.search") }}</p>
          </button>
        </div>
      </div>
    </div>
    <div class="serverfroms">
      <el-table :data="tableData.list" style="width: 100%">
        <template slot="empty">
          <div class="noCart">{{ $t("lang.tableNull") }}</div>
        </template>
        <el-table-column
          :label="$t('lang.Nickname')"
          width="250"
          align="center"
        >
          <template #default="scope">
            <span v-if="scope.row.name">{{ scope.row.name }}</span>
            <span v-else>{{ scope.row.email }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="email"
          :label="$t('lang.accountnumber')"
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column
          :label="$t('lang.Isitonline')"
          align="center"
          width="200"
        >
          <template #default="scope">
            <span v-if="scope.row.state == 1">{{ $t("lang.onLine") }}</span>
            <span v-else>{{ $t("lang.offline") }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.state')" align="center" width="150">
          <template #default="scope">
            <span v-if="scope.row.status == 1">{{ $t("lang.normal") }}</span>
            <span v-else>{{ $t("lang.nonormal") }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="deviceNum"
          :label="$t('lang.Numberofonlinedevices')"
          align="center"
					 width="150"
        >
        </el-table-column> -->
        <el-table-column
          :label="$t('lang.Totalalls')"
          align="center"
          width="200"
        >
          <template #default="scope">
            {{ scope.row.deviceNum }}/
            <a href="javascrept:;" style="text-decoration: none"
              ><span @click="Jump(scope.row)" style="color: darkorange">{{
                scope.row.deviceTotal
              }}</span></a
            >
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('lang.Totalstorages')"
          width="200"
          align="center"
        >
          <template #default="scope">
            <span>{{ scope.row.amount | amoutsa }}GB</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('lang.Storagepeak')"
          width="200"
          align="center"
        >
          <template #default="scope">
            <span>{{ scope.row.peak | amoutsa }}GB</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('lang.Storeamount')"
          width="200"
          align="center"
        >
          <template #default="scope">
            <span>{{ scope.row.peakMoney | monsa }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('lang.balances')"
          width="200"
          align="center"
        >
          <template #default="scope">
            <span>{{ scope.row.balance | monsa }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="loginOutTime"
          :label="$t('lang.Lastlogintime')"
          width="250"
          align="center"
        >
        </el-table-column>
        <el-table-column
          :label="$t('lang.operation')"
          align="center"
          width="150"
        >
          <template #default="scope">
            <div class="bottom_tabes">
              <el-tooltip
                effect="dark"
                :content="$t('lang.Disable')"
                placement="left"
                v-if="scope.row.status == 1"
              >
                <div
                  class="bottom_imgs bottom_compile"
                  @mouseenter="enterOne(1, scope.row.id, scope.row.flag1)"
                  @mouseleave="leaveOne(1, scope.row.id, scope.row.flag1)"
                  @click="handleEdit(scope.row)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag1 === 0"
                    src="@/assets/imgs/bottom/jinyong.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag1 === 1"
                    src="@/assets/imgs/bottom/jinyong2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                :content="$t('lang.Enable')"
                placement="left"
                v-else
              >
                <div
                  class="bottom_imgs bottom_delete"
                  @mouseenter="enterOne(2, scope.row.id, scope.row.flag2)"
                  @mouseleave="leaveOne(2, scope.row.id, scope.row.flag2)"
                  @click="handleEdit(scope.row)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag2 === 0"
                    src="@/assets/imgs/bottom/qiyong.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag2 === 1"
                    src="@/assets/imgs/bottom/qiyong2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
              <div class="btn_dise">|</div>
              <el-tooltip
                effect="dark"
                :content="$t('lang.yunwei')"
                placement="left"
              >
                <div
                  class="bottom_imgs bottom_delete"
                  @mouseenter="enterOne(3, scope.row.id, scope.row.flag3)"
                  @mouseleave="leaveOne(3, scope.row.id, scope.row.flag3)"
                  @click="editmsg(scope.row)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag3 === 0"
                    src="@/assets/imgs/bottom/bianji.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag3 === 1"
                    src="@/assets/imgs/bottom/bianji2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
            </div>
            <!-- <el-button
              class="buttons"
              style="background-color: #1061c5"
              v-if="scope.row.status == 1"
              size="mini"
              @click="handleEdit(scope.row)"
              >{{ $t("lang.Disable") }}</el-button
            >
            <el-button
              class="buttons"
              style="background-color: #307b37"
              v-else
              size="mini"
              @click="handleEdit(scope.row)"
              >{{ $t("lang.Enable") }}</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; align-items: center" v-if="tableData.list">
        <div class="allNum">{{ $t("lang.total") }}: {{ tableData.total }}</div>
        <el-pagination
          background
          v-if="tableData.list"
          layout="prev, pager, next"
          :total="tableData.total"
          @current-change="handlePageCurrentChange"
          :prev-text="$t('lang.previouspage')"
          :next-text="$t('lang.nextpage')"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 添加管理员模态框 -->
    <div class="addAmin">
      <el-dialog
        :title="$t('lang.yunweiad')"
        v-model="addval"
        width="6.3rem"
        :visible.sync="addval"
        :close-on-click-modal="false"
      >
        <div style="margin-bottom: 0.1rem">
          <el-table
            :data="tablists"
            style="width: 100%"
            @row-click="cellclick"
            :highlight-current-row="true"
          >
            <el-table-column
              prop="name"
              :label="$t('lang.equipmentName')"
              width="180"
            >
            </el-table-column>
            <el-table-column prop="mid" label="mid"> </el-table-column>
          </el-table>
        </div>
        <span class="dialog-footer">
          <button @click="addval = false">{{ $t("lang.cancel") }}</button>
          <el-button size="small" @click="addAmin">{{
            $t("lang.determine")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import * as user from "@/api/user.js";
import { getEqList } from "@/api/apis";
export default {
  data() {
    return {
      value: "",
      input: "",
      addval: false,
      parsm: {
        page: 1,
        size: 10,
        nameOrEmail: "",
        status: "",
        state: "",
      },
      tablists: [],
      tableData: [],
      mid: "",
    };
  },
  mounted() {
    this.getList();
  },
  filters: {
    amoutsa(val) {
      if (val) {
        return val.toFixed(4);
      } else {
        return val;
      }
    },
    monsa(val) {
      if (val) {
        return val.toFixed(2);
      } else {
        return val;
      }
    },
  },
  methods: {
    cellclick(row) {
      this.mid = row.mid;
      console.log(row);
    },
    addAmin() {
      if (this.mid) {
        this.$router.push({
          path: "/nav/manage/components",
          query: { mid: this.mid },
        });
      }
      this.addval = false;
    },
    //获取设备列表
    async getdevlist(email) {
      let params = {
        email: email,
        type: "0",
      };
      let res = await getEqList(params);

      this.tablists = res.data;
      //console.log(this.tals)
    },
    enterOne(index, ids, flag) {
      for (let i in this.tableData.list) {
        if (index === 1) {
          if (ids == this.tableData.list[i].id && flag === 0) {
            this.tableData.list[i].flag1 = 1;
          }
        }
        if (index === 2) {
          if (ids == this.tableData.list[i].id && flag === 0) {
            this.tableData.list[i].flag2 = 1;
          }
        }
        if (index === 3) {
          if (ids == this.tableData.list[i].id && flag === 0) {
            this.tableData.list[i].flag3 = 1;
          }
        }
      }
    },
    leaveOne(index, ids, flag) {
      for (let i in this.tableData.list) {
        if (index === 1) {
          if (ids == this.tableData.list[i].id && flag === 1) {
            this.tableData.list[i].flag1 = 0;
          }
        }
        if (index === 2) {
          if (ids == this.tableData.list[i].id && flag === 1) {
            this.tableData.list[i].flag2 = 0;
          }
        }
        if (index === 3) {
          if (ids == this.tableData.list[i].id && flag === 1) {
            this.tableData.list[i].flag3 = 0;
          }
        }
      }
    },

    editmsg(row) {
      this.addval = true;
      this.getdevlist(row.email);
    },
    //状态筛选
    stauts(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.status = e;
      this.getList();
    },
    stautasond(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.state = e;
      this.getList();
    },
    //姓名邮箱筛选
    sear() {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.nameOrEmail = this.input;
      this.getList();
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.parsm.page = page;
      this.getList();
    },
    //获取设备用户列表
    async getList() {
      let res = await user.userlist(this.parsm);
      console.log(res);
      res.data.list.forEach((item) => {
        item.flag1 = 0;
        item.flag2 = 0;
        item.flag3 = 0;
      });
      this.tableData = res.data;
    },
    //跳转此用户的设备列表
    Jump(row) {
      this.$router.push({
        path: "userequilist",
        query: { email: row.email },
      });
    },
    //禁用、启用
    handleEdit(row) {
      // let email=JSON.parse(sessionStorage.getItem("user")).email
      let states;
      if (row.status == 1) {
        states = 2;
      }
      if (row.status == 2) {
        states = 1;
      }
      let data = {
        email: row.email,
        status: states,
      };
      user.Disable(data).then((res) => {
        //console.log(res)
        this.$message.success(this.$t("lang.Operations"));
        this.getList();
      });
    },
  },
};
</script>
<style>
.el-input {
  font-size: 0.16rem !important;
}
</style>
<style lang="less" scoped>
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;
}
.buttons {
  // padding: 0px;
  // width: 82px;
  // height: 42px;
  outline-style: none;
  border: none;
  border-radius: 0.04rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.addAmin,
.editadmin {
  .dialog-footer {
    button {
      width: 0.92rem;
      height: 0.46rem;
      border: none;
      outline-style: none;
      background: #fff;
      border: 0.01rem solid #438749;
      border-radius: 0.1rem;
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: 500;
      &:first-of-type {
        margin-right: 0.36rem;
        color: #438749;
      }
      &:last-of-type {
        background: #438749;
        color: #ffffff;
      }
    }
  }
}
.aminMsg_a input {
  width: 60%;
}
</style>
